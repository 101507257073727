// lib/glitchEffect.js

export const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#$%^&*()_+ÇýùéêšŽ¥óćĒƒï£×åßΩ≈øπ∆';
export const charsR = 'アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン0123456789';

export function glitchEffect(setMessage, setGlitchedRabbit, originalMessage) {
    // Garantir que temos uma mensagem original válida
    if (!originalMessage) {
        console.error("glitchEffect: originalMessage is necessary");
        return () => {};
    }

    let glitchInterval;
    const activateGlitch = () => {
        glitchInterval = setInterval(() => {
            let glitchedMessage = '';
            for (let i = 0; i < originalMessage.length; i++) {
                if (Math.random() < 0.1) {
                    glitchedMessage += chars[Math.floor(Math.random() * chars.length)];
                } else {
                    glitchedMessage += originalMessage[i];
                }
            }
            // Certifique-se de que setMessage é uma função antes de chamar
            if (typeof setMessage === 'function') {
                setMessage(glitchedMessage);
            }
        }, 30);
    };

    if (setGlitchedRabbit && typeof setGlitchedRabbit === 'function') {
        setInterval(() => {
            for (let i = 0; i < originalMessage.length; i++) {
                if (Math.random() < 0.1) {
                    setGlitchedRabbit(false);
                } else {
                    setGlitchedRabbit(true);
                }
            }
        }, 60);
    }

    const timer = setInterval(() => {
        activateGlitch();
        if (typeof setMessage === 'function') {
            setMessage(originalMessage);
        }
        setTimeout(() => {
            clearInterval(glitchInterval);
            if (typeof setMessage === 'function') {
                setMessage(originalMessage);
            }
        }, 500);
    }, 1500);

    return () => {
        clearInterval(timer);
        clearInterval(glitchInterval);
    };
}