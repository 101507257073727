import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './About.css';
import DefaultHelmet from '../DefaultHelmet';

import { glitchEffect } from '../../lib/glitchEffect';

function About() {
    const navItemsRef = useRef([]);
    // const [message, setMessage] = useState(null);
    const [originalMessages] = useState(['Experience', 'Projects', 'Articles', 'Contact']);
    const [glitchState, setGlitchState] = useState({ glitchingLi: null, originalMessage: null });
    
    const lastRandomIndexRef = useRef(null);
    const glitchIntervalRef = useRef(null);
    const clearPreviousEffectRef = useRef(null);

    const applyGlitchEffectToRandomLi = () => {
        let randomIndex;
        do {
            randomIndex = Math.floor(Math.random() * navItemsRef.current.length);
        } while (randomIndex === lastRandomIndexRef.current && navItemsRef.current.length > 1);

        lastRandomIndexRef.current = randomIndex;

        const randomLi = navItemsRef.current[randomIndex];

        if (randomLi) {
            const newOriginalMessage = originalMessages[randomIndex] || randomLi.textContent;

            // Guardar o novo li e a nova mensagem original
            setGlitchState({ glitchingLi: randomLi, originalMessage: newOriginalMessage });

            // Criamos uma função para atualizar o texto do elemento li específico
            const updateLiText = (text) => {
                if (randomLi) {
                    randomLi.textContent = text;
                }
            };

            const clearIntervals = glitchEffect(updateLiText, null, newOriginalMessage);
            return clearIntervals;
        }
        
        return () => {};
    };

    const clearGlitchEffect = () => {
        if (clearPreviousEffectRef.current) {
            clearPreviousEffectRef.current();
            clearPreviousEffectRef.current = null;
        }
        
        navItemsRef.current.forEach((li, index) => {
            if (li && originalMessages[index]) {
                li.textContent = originalMessages[index];
            }
        });
    };

    useLayoutEffect(() => {
        // Atualize navItemsRef com os elementos li
        const liElements = document.querySelectorAll('nav ul li');
        navItemsRef.current = Array.from(liElements);

        // Verifique se navItemsRef.current contém elementos
        if (navItemsRef.current.length > 0) {
            navItemsRef.current.forEach((li, index) => {
                if (li && originalMessages[index]) {
                    li.textContent = originalMessages[index];
                    const width = li.getBoundingClientRect().width;
                    li.style.width = `${width}px`;
                }
            });
        }
    }, [originalMessages]);

    useEffect(() => {
        // Limpar qualquer efeito existente
        clearGlitchEffect();
        
        // Iniciar um novo efeito
        clearPreviousEffectRef.current = applyGlitchEffectToRandomLi();
      
        // Configurar o intervalo para alternar entre os itens
        glitchIntervalRef.current = setInterval(() => {
            // Limpar o efeito anterior
            if (clearPreviousEffectRef.current) {
                clearPreviousEffectRef.current();
            }
            
            // Restaurar a mensagem original
            if (glitchState.glitchingLi && glitchState.originalMessage) {
                glitchState.glitchingLi.textContent = glitchState.originalMessage;
            }
      
            // Aplicar um novo efeito
            clearPreviousEffectRef.current = applyGlitchEffectToRandomLi();
        }, 2000); // Alterar o efeito a cada 2 segundos
      
        // Limpar o intervalo ao desmontar o componente
        return () => {
            clearInterval(glitchIntervalRef.current);
            clearGlitchEffect();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <div className="outer-div">
        <DefaultHelmet />
        <Helmet>
            <title>esthon.com</title>
        </Helmet>
        <div className="content-about">
            <h1>Greetings...</h1>
            <br />
            <p>I'm Esthon Jr, a seasoned IT Engineer with a lifelong dedication to technology, specializing in Web Development, Blockchain, and AI. Passionate about innovating and transforming ideas into effective digital solutions. Committed to navigating the digital landscape with creativity and expertise, I aim to empower partners in achieving their goals.</p>
            <br />
            <nav>
                <ul>
                    <Link to="/resume"><li>Experience</li></Link>
                    <Link to="/projects"><li>Projects</li></Link>
                    <Link to="/articles"><li>Articles</li></Link>
                    <Link to="/contact"><li>Contact</li></Link>
                </ul>
            </nav>
        </div>        
    </div>
  );
}

export default About;