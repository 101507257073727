import React from 'react';

function Interests(props) {
    return (
      <div className="interests">
        <ul>
          {props.interests.details.map((detail, index) => <li key={index}>{detail}</li>)}
        </ul>
      </div>
    );
}

export default Interests;