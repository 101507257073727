import React from 'react';

function Skills(props) {
    return (
      <div>
        {props.skills.map((skill, index) => 
            <div className="job-description" key={'skill-' + index}>
                <h4>{skill.title}</h4>
                <p>{skill.skills}</p>
            </div>
        )}
      </div>
    );
}

export default Skills;
